import React from 'react';
import styled from 'styled-components';
import * as t from '../Typography';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import Layout, { Content } from '../components/Layout';

const BlogListWrapper = styled(Content)`
  padding-top: 130px;
  width: 1030px;
  padding-bottom: 30px;
`;

const Hero = styled.div`
  background-image: url(http://merryukulele.com/wps/wp-content/uploads/2018/06/Book-Reviews-책-리뷰-BW.jpg);
  height: 318px;
  position: relative;
  margin-bottom: 50px;
`;

const HeroHeader = styled.div`
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
`;

const Main = styled.div`
  display: block;
  font-size: 38px;
  text-align: center;
`;

const HeroSubHeader = styled.div`
  color: #cccccc;
  font-size: 19px;
  margin-top: 7px;
  display: block;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
`;

const BookShelf = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  transform: translateX(20px);
  margin: 0 auto;
`;

const BookItem = styled.div`
  background-size: cover;
  width: 190px;
  height: 280px;
  transition: transform 0.3s;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  position: relative;
  transition: all 0.2s ease-in-out;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 38px;

  &:hover {
    box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
    transform: translateY(-0.1rem);
  }
`;

const BookshelfTitle = styled(t.H2)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
`;

const ArticleImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
`;

const Avatar = styled.img`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 5px solid white;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  right: -10px;
  bottom: -11px;
`;

class BookPage extends React.Component {
  renderImage(node) {
    if (node.frontmatter.thumbnail.childImageSharp) {
      return <ArticleImg sizes={node.frontmatter.thumbnail.childImageSharp.sizes} />;
    }

    return <Image src={node.frontmatter.thumbnail.publicURL} />;
  }

  render() {
    const postList = this.props.data.allMarkdownRemark;

    return (
      <Layout>
        <BlogListWrapper>
          <Hero>
            <HeroHeader>
              <Main>BOOK REVIEWS</Main>
              <HeroSubHeader>Knowing is not enough. We must apply.</HeroSubHeader>
            </HeroHeader>
          </Hero>
          <BookshelfTitle>My Bookshelf</BookshelfTitle>
        </BlogListWrapper>
        <BookShelf>
          {postList.edges.map(({ node }) => {
            if (node.frontmatter.path !== '/book') {
              return null;
            }

            return (
              <Link to={`/book${node.fields.slug}`} className="link">
                <BookItem className="post-list">
                  {this.renderImage(node)}
                  {node.frontmatter.avatar && <Avatar src={node.frontmatter.avatar} />}
                </BookItem>
              </Link>
            );
          })}
        </BookShelf>
      </Layout>
    );
  }
}
export default BookPage;

export const bookQuery = graphql`
  query BookQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            path
            avatar
            thumbnail {
              publicURL
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
